@import "@/styles/shared";
// Global styling, normalize overwritten
@import 'fonts';
@import 'shared';

*,
*::before,
*::after {
  box-sizing: inherit;
}

/* stylelint-disable-next-line */
:root {
  // Colors
  --white: #{$white};
  --black: #{$black};
  --red: #{$red};
  --ginger: #{$ginger};
  --soft-ginger: #{$soft-ginger};
  --dough: #{$dough};
  --flour: #{$flour};
  --wine: #{$wine};
  --walnut: #{$walnut};
  --burgundy: #{$burgundy};
  --spice: #{$spice};
  --clay: #{$clay};
  --pine: #{$pine};
  --jade-green: #{$jade-green};
  --scallion: #{$scallion};
  --azurite: #{$azurite};
  --glaze-blue: #{$glaze-blue};
  --grey-1200: #{$grey-1200};
  --grey-1100: #{$grey-1100};
  --grey-1000: #{$grey-1000};
  --grey-900: #{$grey-900};
  --grey-800: #{$grey-800};
  --grey-700: #{$grey-700};
  --grey-600: #{$grey-600};
  --grey-500: #{$grey-500};
  --grey-400: #{$grey-400};
  --grey-300: #{$grey-300};
  --grey-200: #{$grey-200};
  --grey-100: #{$grey-100};
  --grey-50: #{$grey-50};

  // Grid
  --gutter: #{$gutter};
  --page-gutter: #{$page-gutter-mobile};

  @include bp(tablet) {
    --page-gutter: #{$page-gutter};
  }

  // General
  --section-spacing: #{$section-spacing-mobile};
  --header-height: #{$header-height};
  --header-height-xsmobile: #{$header-height-xsmobile};
  --section-max-width: #{$section-max-width-mobile};
  --narrow-section-max-width: 812px;
  --stickybanner-height: #{$stickybanner-height};
  --stickybanner-height-xsmobile: #{$stickybanner-height-xsmobile};

  @include bp(tablet) {
    --section-max-width: #{$section-max-width};
  }

  // Buttons
  --circle-button-dimensions: #{$circle-button-dimensions};

  // Sticky nav section
  --content-with-sticky-nav-button-height: #{px(60)};

  @include bp(tablet) {
    --section-spacing: #{$section-spacing-desktop};
  }

  // Components
  --button-height: #{$button-height};
  --button-padding: #{$button-padding};
}

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: initial;
  min-height: 100%;
  height: auto;
  font-size: 10px;
}

body {
  @include light-theme;
  font-family: $body-fonts;
  margin: 0;
  padding: 0;
  min-height: 100%;
  font-size: px(16);
  height: auto;
  background-color: var(--bg-color);
  color: var(--text-color);

  // Disabling interaction
  &[data-enable-interaction='false'] {
    pointer-events: none !important;

    * {
      pointer-events: none !important;
    }
  }
}

main {
  @include z-index(main);
  @include vh(100, min-height);
  position: relative;

  [data-browser='chrome'][data-device='mobile'] & {
    min-height: 100vh; // fix for android/chrome browsers
  }
}

a {
  color: inherit;
  text-decoration: none;
}

ul,
dl,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

li,
dt,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote {
  margin: 0;
  padding: 0;
}

// ====================================
// Lenis
// ====================================
html.lenis {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
