@use 'sass:map';

// ================================================
// Breakpoints
// ================================================
$layout: (
  xsmobile: 400,
  mobile: 768,
  tablet: 967,
  laptop: 1200,
  desktop: 1512,
  xl: 1800,
);
$layout-xsmobile: map.get($layout, xsmobile) + px;
$layout-mobile: map.get($layout, mobile) + px;
$layout-tablet: map.get($layout, tablet) + px;
$layout-laptop: map.get($layout, laptop) + px;
$layout-desktop: map.get($layout, desktop) + px;
$layout-xl: map.get($layout, xl) + px;

// ================================================
// Colors
// ================================================

// General
$white: #fff;
$white-rgb: 255, 255, 255;
$black: #000;
$black-rgb: 0, 0, 0;

// Brand
$red: #d7000f;
$ginger: #d6cab5;
$soft-ginger: #e7e1cf;
$dough: #efebda;
$flour: #fffceb;
$flour-rgb: 255, 252, 235;
$wine: #a7000c;
$walnut: #472c13;
$burgundy: #610303;
$spice: #783412;
$clay: #bb480d;
$pine: #074b1e;
$jade-green: #4baa2f;
$scallion: #91e063;
$azurite: #02348a;
$glaze-blue: #7bb3f7;

// Gray
$grey-1200: #000;
$grey-1100: #101010;
$grey-1000: #1e1e1e;
$grey-900: #2d2d2d;
$grey-800: #474747;
$grey-700: #565656;
$grey-600: #666;
$grey-500: #666;
$grey-400: #aaa;
$grey-300: #bababa;
$grey-200: #d8d8d8;
$grey-100: #eaeaea;
$grey-50: #f8f8f8;

// Background colors
$background-colours: dough, ginger, black, burgundy;
$placeholder-opacity: 0.65;

// ================================================
// Grid
// ================================================
$gutter: px(20);
$page-gutter: px(32);
$page-gutter-mobile: px(24);

// ================================================
// General
// ================================================
$transition-short: 0.25s;
$section-spacing-desktop: #{px(160)};
$section-spacing-mobile: #{px(120)};
$section-max-width: px(1440);
$section-max-width-mobile: px(800);
$header-height: px(93);
$header-height-xsmobile: px(93);
$stickybanner-height: px(48);
$stickybanner-height-xsmobile: px(64);

// ================================================
// Components
// ================================================
$button-height: px(52);
$button-padding: px(24);
$circle-button-dimensions: px(56);

// ================================================
// Fonts
// ================================================
$display-font-name: 'Heldane Display';
$body-font-name: 'Suisse Intl';
$display-fonts: $display-font-name, serif;
$body-fonts: $body-font-name, sans-serif;

// ================================================
// z-index data (see z-index mixin)
// ================================================
$elements: main, content-with-sticky-navigation-mobile, navigation-bg, navigation-mobile, navigation,
  resaurant-navigation, merch-slide-out, video-popout, mobile-in-progress, preview-button;
