@use 'sass:list';
@import 'vars';

// ================================================
// GPU fix
// ================================================
@mixin gpu {
  backface-visibility: hidden;
  transform-style: preserve-3d;
  perspective: 1000px;

  .safari & {
    perspective: none; // otherwise text and icons become blurry
  }
}

// ================================================
// Same height/width box
// ================================================
@mixin box($width, $height: $width) {
  width: $width;
  height: $height;
}

// ================================================
// REM calc
// ================================================
@function px($px) {
  @return $px * 0.1rem;
}

// ================================================
// Top left positioning
// ================================================
@mixin position-0 {
  top: 0;
  left: 0;
}

// ================================================
// Full width/height positioning
// ================================================
@mixin position-100($pos: relative) {
  position: $pos;
  @include position-0;
  @include box(100%);
}

// ================================================
// Center things horizontally in flexbox
// ================================================
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

// ================================================
// Center things vertically in flexbox
// ================================================
@mixin flex-center-vert {
  display: flex;
  align-items: center;
}

// ================================================
// Center something with abs/fixed positioning
// ================================================
@mixin transform-center {
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

// ================================================
// Z-Index for main components
// ================================================
@mixin z-index($id) {
  z-index: list.index($elements, $id) + 100;
}

// ================================================
// Media queries
// ================================================
@mixin bp($size, $is-max-width: false) {
  @if $is-max-width {
    @media (max-width: #{map-get($layout, $size)}px) {
      @content;
    }
  } @else {
    @media (min-width: #{map-get($layout, $size)}px) {
      @content;
    }
  }
}

@mixin bp-custom($query, $is-max-width: false) {
  @if $is-max-width {
    @media (max-width: #{$query}) {
      @content;
    }
  } @else {
    @media (min-width: #{$query}) {
      @content;
    }
  }
}

@mixin bp-height($px, $is-max-width: false) {
  @if $is-max-width {
    @media (max-height: #{$px}px) {
      @content;
    }
  } @else {
    @media (min-height: #{$px}px) {
      @content;
    }
  }
}

// ================================================
// Hover
// ================================================
@mixin hover {
  @media (pointer: fine) {
    &:hover {
      @content;
    }
  }
}

// ================================================
// Reset ul
// ================================================
@mixin reset-ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

// ================================================
// Reset button
// ================================================
@mixin reset-button {
  border: none;
  cursor: pointer;
  background-color: transparent;
  padding: 0;
  text-align: inherit;
  letter-spacing: inherit;
  font-size: inherit;
  text-transform: inherit;
  display: inline-block;
  margin: 0;
  text-decoration: none;
  appearance: none;
  color: currentColor;
}

// ================================================
// Button
// ================================================

@mixin button-colors {
  background-color: var(--button-bg);
  backdrop-filter: blur(#{px(14)});
  border: px(1) solid transparent;
  color: var(--text-bold);
}

@mixin button-transition-values {
  transition: border-color $transition-short, background-color $transition-short, padding-right $transition-short;
}

// ================================================
// Height
// ================================================

@mixin vh($vh, $property: null) {
  @if $property {
    #{$property}: #{$vh}vh;
    #{$property}: calc(var(--vh, 1vh) * #{$vh});

    body[data-browser='chrome'][data-device='mobile'] & {
      #{$property}: #{$vh}vh; // must be this vh on android
    }
  } @else {
    height: #{$vh}vh;
    height: calc(var(--vh, 1vh) * #{$vh});

    body[data-browser='chrome'][data-device='mobile'] & {
      height: #{$vh}vh; // must be this vh on android
    }
  }
}

// ================================================
// Hide scrollbar
// ================================================

@mixin hide-scrollbar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}

// ================================================
// Reduced motion
// ================================================

@mixin reduced-motion {
  @media (prefers-reduced-motion: reduce) {
    @content;
  }
}

// ================================================
// Language Targeting
// ================================================
@mixin lang($lang) {
  @if $lang {
    html[lang='#{$lang}'] & {
      @content;
    }
  }
}

// ================================================
// Theming
// ================================================

@mixin dark-theme {
  // rgb
  --fg-rgb: #{$white-rgb};
  --flour-rgb: #{$flour-rgb};

  // Text
  --text-color: var(--white);

  // Background
  --bg-color: var(--black);
  --bg-subtle-color: rgba(var(--fg-rgb), 0.05);

  // Button - Primary
  --button-primary-label-color: var(--white);
  --button-primary-outline-color: var(--red);
  --button-primary-background-color: var(--red);
  --button-primary-label-hover-color: var(--white);
  --button-primary-outline-hover-color: var(--wine);
  --button-primary-background-hover-color: var(--wine);

  // Button - Secondary
  --button-secondary-label-color: var(--white);
  --button-secondary-outline-color: rgba(#{$white-rgb}, 0.4);
  --button-secondary-background-color: transparent;
  --button-secondary-label-hover-color: var(--white);
  --button-secondary-outline-hover-color: var(--red);
  --button-secondary-background-hover-color: var(--red);

  // Button - Tertiary
  --button-tertiary-label-color: var(--white);
  --button-tertiary-outline-color: rgba(#{$white-rgb}, 0.4);
  --button-tertiary-background-color: transparent;
  --button-tertiary-label-hover-color: var(--black);
  --button-tertiary-outline-hover-color: var(--white);
  --button-tertiary-background-hover-color: var(--white);

  // Form
  --input-text-color: var(--white);
  --input-placeholder-color: var(--white);
  --input-border-color: rgba(#{$white-rgb}, 0.2);
  --error-message-text-color: var(--red);
  --success-message-text-color: var(--jade-green);
}

@mixin light-theme {
  // rgb
  --fg-rgb: #{$black-rgb};
  --flour-rgb: #{$flour-rgb};

  // Text
  --text-color: var(--black);

  // Background
  --bg-color: var(--dough);
  --bg-subtle-color: rgba(var(--fg-rgb), 0.05);

  // Button - Primary
  --button-primary-label-color: var(--white);
  --button-primary-outline-color: var(--red);
  --button-primary-background-color: var(--red);
  --button-primary-label-hover-color: var(--white);
  --button-primary-outline-hover-color: var(--wine);
  --button-primary-background-hover-color: var(--wine);

  // Button - Secondary
  --button-secondary-label-color: var(--black);
  --button-secondary-outline-color: rgba(#{$black-rgb}, 0.1);
  --button-secondary-background-color: transparent;
  --button-secondary-label-hover-color: var(--white);
  --button-secondary-outline-hover-color: var(--red);
  --button-secondary-background-hover-color: var(--red);

  // Button - Tertiary
  --button-tertiary-label-color: var(--black);
  --button-tertiary-outline-color: rgba(#{$black-rgb}, 0.1);
  --button-tertiary-background-color: transparent;
  --button-tertiary-label-hover-color: var(--white);
  --button-tertiary-outline-hover-color: var(--black);
  --button-tertiary-background-hover-color: var(--black);

  // Form
  --input-text-color: var(--black);
  --input-placeholder-color: var(--black);
  --input-border-color: rgba(#{$black-rgb}, 0.2);
  --error-message-text-color: var(--red);
  --success-message-text-color: var(--jade-green);
}

// ================================================
// Box Styles
// ================================================

@mixin box-styling($type) {
  position: relative;
  overflow: hidden;
  background-color: var(--bg-subtle-color);

  @if $type == arch {
    border-top-left-radius: 20000px;
    border-top-right-radius: 20000px;
    aspect-ratio: 445/477;
  }
  @if $type == roundedCorners {
    border-radius: 16%;
    aspect-ratio: 1/1;
  }
  @if $type == square {
    aspect-ratio: 1/1;
  }
}

// ================================================
// Grid
// ================================================

@mixin grid {
  display: grid;
  gap: var(--gutter);
  grid-template-columns: repeat(4, 1fr);

  @include bp(mobile) {
    grid-template-columns: repeat(12, 1fr);
  }
}

// ================================================
// Rich Text
// ================================================

@mixin rich-text-p-margin {
  margin-bottom: px(24);
}

// Red Asterisk
// ================================================

@mixin red-asterisk {
  color: var(--red);
  font-size: 2em;
  height: 0.15em;
  font-family: $body-fonts;
}
