// Fonts
// Location of all the font-families and font-related css
// Best practices:
// 1. Avoid loading as many fonts as possible, e.x font styles, font families
// 2. Optimize the font, remove the characters set that the site won't use: languages
// 3. Include the unicode range in case you couldn't remove the characters. Included just Latin by default
// 4. Change fonts just changing the font-weight

@import 'vars';

$font-path: '../assets/fonts/' !default;

/*
100	Thin (Hairline)
200	Extra Light (Ultra Light)
300	Light
400	Normal (Regular)
500	Medium
600	Semi Bold (Demi Bold)
700	Bold
800	Extra Bold (Ultra Bold)
900	Black (Heavy)
950	Extra Black (Ultra Black)

@font-face {
    font-family: $faktum-font;
    src: url("#{$font-path}font-name-bold.woff") format("woff");
    font-weight: 600;
    font-display: swap;
}
*/

// Display

/* stylelint-disable-next-line scss/operator-no-unspaced */
$unicode-range: U+0041-005A, U+0061-007A;

@font-face {
  font-family: $display-font-name;
  src: url('#{$font-path}heldane-display-regular-italic.woff2') format('woff2');
  font-weight: 400;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: $display-font-name;
  src: url('#{$font-path}heldane-display-regular.woff2') format('woff2');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: $display-font-name;
  src: url('#{$font-path}heldane-display-medium-italic.woff2') format('woff2');
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: $display-font-name;
  src: url('#{$font-path}heldane-display-medium.woff2') format('woff2');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: $display-font-name;
  src: url('#{$font-path}heldane-display-bold-italic.woff2') format('woff2');
  font-weight: 700;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: $display-font-name;
  src: url('#{$font-path}heldane-display-bold.woff2') format('woff2');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

// Body

@font-face {
  font-family: $body-font-name;
  src: url('#{$font-path}SuisseIntl-Regular-WebM.woff') format('woff');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
  unicode-range: $unicode-range;
}

@font-face {
  font-family: $body-font-name;
  src: url('#{$font-path}SuisseIntl-RegularItalic-WebM.woff') format('woff');
  font-weight: 400;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: $body-font-name;
  src: url('#{$font-path}SuisseIntl-Medium-WebM.woff') format('woff');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
  unicode-range: $unicode-range;
}

@font-face {
  font-family: $body-font-name;
  src: url('#{$font-path}SuisseIntl-MediumItalic-WebM.woff') format('woff');
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: $body-font-name;
  src: url('#{$font-path}SuisseIntl-Bold-WebM.woff') format('woff');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
  unicode-range: $unicode-range;
}

@font-face {
  font-family: $body-font-name;
  src: url('#{$font-path}SuisseIntl-BoldItalic-WebM.woff') format('woff');
  font-weight: 700;
  font-display: swap;
  font-style: italic;
}
